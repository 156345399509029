import React from 'react';

const ScoreList = (props) => {
    return (
        <div className="position-relative m-4pt">
            <a className="d-flex flex-column border-1 rounded bg-alt px-16pt py-12pt lh-1" href>
                <small><strong className="text-black-100">{props.course.courseTitle}
                </strong></small>
            </a>
            <span className="position-close-4pt" style={{ cursor: 'pointer' }} onClick={props.handleDelete}>
                <i className="material-icons text-50 icon-16pt">close</i>
            </span>
        </div>
    );
}

export default ScoreList;

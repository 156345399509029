import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './firebase/config';
import Login from './components/Login';
import ForgotPassword from './components/ForgotPassword';
import Dashboard from './components/student/Dashboard';
import AdminDashboard from './components/admin/AdminDashboard';
import Students from './components/admin/Students';
import Programs from './components/admin/Programs';
import Cohorts from './components/admin/Cohorts';
import Courses from './components/admin/Courses';
import Timetables from './components/admin/Timetables';
import Student from './components/admin/Student';
import Program from './components/admin/Program';
import Cohort from './components/admin/Cohort';
import Course from './components/admin/Course';
import Assessment from './components/admin/Assessment';
import Assessments from './components/admin/Assessments';
import Result from './components/admin/Result';
import Compute from './components/admin/Compute';
import ResultDetails from './components/admin/ResultDetails'
import ChangePassword from './components/student/ChangePassword';
import Account from './components/student/Account';
import StudentResultDetails from './components/student/ResultDetails'
import Results from './components/student/Results';
import Instructors from './components/admin/Instructors';
import Instructor from './components/admin/Instructor'



function App () {
    return (
        <Router>
            <div className="App">
                <Switch>
                    <Route path="/" exact component={Login} />
                    <Route path="/reset" component={ForgotPassword} />
                    <Route path="/dashboard/:studentId" component={Dashboard} />
                    <Route path="/admin-dashboard" component={AdminDashboard} />
                    <Route path="/students" component={Students} />
                    <Route path="/student" component={Student} />
                    <Route path="/instructors" component={Instructors} />
                    <Route path="/instructor" component={Instructor} />
                    <Route path="/programs" component={Programs} />
                    <Route path="/program" component={Program} />
                    <Route path="/cohorts" component={Cohorts} />
                    <Route path="/cohort" component={Cohort} />
                    <Route path="/courses" component={Courses} />
                    <Route path="/course" component={Course} />
                    <Route path="/timetables" component={Timetables} />
                    <Route path="/assessment" component={Assessment} />
                    <Route path="/assessments" component={Assessments} />
                    <Route path="/result/:studentId" exact component={Result} />
                    <Route path="/compute/:studentId/:id" exact component={Compute} />
                    <Route path="/result-details/:studentId/:id" exact component={ResultDetails} />
                    <Route path="/results/:studentId" exact component={Results} />
                    <Route path="/account/:studentId" exact component={Account} />
                    <Route path="/results/details/:studentId/:id" exact component={StudentResultDetails} />
                    <Route path="/change-password/:studentId" exact component={ChangePassword} />
                </Switch>
            </div>
        </Router>
    );
}

export default App;
import React, { useState, useEffect } from 'react';
import { firestore } from "../../firebase/config";
import { Link, useParams } from 'react-router-dom';
import { logout } from '../../firebase/auth';
import { useHistory, Redirect } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';
import ResultList from './ResultList'
import Header from './Header';
import Loader from '../../Loader'

const Dashboard = () => {

    const history = useHistory();
    const studentId = useParams().studentId;
    const [ student, setStudent ] = useState("");

    useEffect(() => {
        firestore
            .collection(`students`)
            .doc(studentId)
            .get().then((document) => {
                if (document.exists) {
                    setStudent(document.data());
                }
            })
    }, []);

    const logoutUser = async () => {
        localStorage.clear();
        await logout();
        history.push('/');
    };

    const [ results, setResult ] = useState([]);
    useEffect(() => {
        firestore
            .collection('result')
            .doc(studentId)
            .collection('results').where('status', '==', 'published')
            .onSnapshot(snap => {
                const resultList = snap.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setResult(resultList);
            });
    }, []);

    const auth = localStorage.getItem('auth');

    if (auth !== "loggedIn") {
        return <Redirect to="/" />
    }


    if (student)
        return (
            <div>
                <div className="mdk-header-layout js-mdk-header-layout">
                    <Header />
                    <div className="mdk-header-layout__content page-content mt-64pt">

                        <div className="mdk-box bg-primary js-mdk-box mb-0" data-effects="blend-background">
                            <div className="mdk-box__content">
                                <div className="hero py-64pt text-center text-sm-left">
                                    <div className="container page__container">
                                        <div className="container page__container d-flex flex-column flex-md-row align-items-center text-center text-md-left">
                                            <div className="avatar avatar-xxl p-16pt">
                                                <img src={student.profilePhotoUrl === "" ? "/assets/images/logo/user.png" : student.profilePhotoUrl}
                                                    alt="people" width={100} className="avatar-img rounded-circle" />
                                            </div>
                                            <div className="flex mb-32pt mb-md-0">
                                                <h2 className="text-white mb-0">{student.displayName}</h2>
                                                <p class="lead measure-hero-lead mx-auto mx-md-0 text-white text-shadow my-4pt">{student.program}</p>
                                                <p className="lead text-white-50 d-flex align-items-center">{student.studentNumber}, <span className="ml-16pt d-flex align-items-center">
                                                    {student.cohort} cohort</span></p>
                                            </div>
                                            <a href={`/results/${studentId}`} className="btn btn-outline-white mb-16pt mb-sm-0 mr-sm-16pt">View All Results <i className="material-icons icon--right"></i></a>
                                            <a href={`/account/${studentId}`} className="btn btn-white">View Profile</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container page__container">
                            <div className="page-section">
                                <div className="row mb-lg-8pt">
                                    <div className="col-lg-12">
                                        <div className="page-separator">
                                            <div className="page-separator__text">Recent Results</div>
                                        </div>

                                        <div className="card mb-0">
                                            <div className="table-responsive" data-toggle="lists" data-lists-sort-by="js-lists-values-employee-name" data-lists-values="[&quot;js-lists-values-employee-name&quot;, &quot;js-lists-values-employer-name&quot;, &quot;js-lists-values-projects&quot;, &quot;js-lists-values-activity&quot;, &quot;js-lists-values-earnings&quot;]">
                                                <div className="card-header">
                                                </div>
                                                <table className="table mb-0 thead-border-top-0 table-nowrap">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ width: 7 }}>  </th>
                                                            <th>Result Year</th>
                                                            <th className="text-center" style={{ width: 37 }}> Semester </th>
                                                            <th style={{ width: 37 }}> Date Created </th>
                                                            <th style={{ width: 37 }}>  </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list" id="staff">
                                                        {
                                                            results.length > 0 ?
                                                                results.map((result) => {
                                                                    return <ResultList result={result} key={result.id} />
                                                                })
                                                                :
                                                                <tr>
                                                                    <td colSpan={5} className="text-center">
                                                                        <p class="lead measure-hero-lead mx-auto mx-md-0 text-black text-shadow my-4pt">You don't have any published result</p>
                                                                    </td>
                                                                </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                    <Footer />

                </div>
                <Navbar />
            </div>
        );
    else
        return <Loader />
}

export default Dashboard;

import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import Header from './Header';
import Footer from './Footer';
import ResultList from './ResultList';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { firestore } from "../../firebase/config";
import { createResult } from '../../firebase/admin';
import Error from '../Error';
import Success from '../Success';
import SelectCohort from './SelectCohort';
import Loader from "../../Loader";

const Result = () => {

    const params = useParams();
    const [ results, setResult ] = useState([]);

    const { register, handleSubmit, reset } = useForm();
    const [ isLoading, setIsLoading ] = useState(false);
    const [ errorMgs, setErrorMgs ] = useState("");
    const [ isErrorFlaq, setIsErrorFlaq ] = useState(false);
    const [ successMgs, setSuccessMgs ] = useState("");
    const [ isSuccessFlaq, setIsSuccessFlaq ] = useState(false);

    useEffect(() => {
        firestore
            .collection('result')
            .doc(params.studentId)
            .collection('results')
            .onSnapshot(snap => {
                const resultList = snap.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                console.log("result: ", resultList)
                setResult(resultList);
            });
    }, []);

    const createNewResult = async (data) => {
        setIsLoading(true);
        let newResult;
        try {
            newResult = await createResult(data);
            reset();
            setSuccessMgs("Result Created Successfully");
            setIsSuccessFlaq(true)
            setIsLoading(false);
        }
        catch (error) {
            setErrorMgs(error.message);
            setIsErrorFlaq(true)
            console.log(error);
            setIsLoading(false);
        }
    };

    const handleDelete = (resultId) => {
        if (window.confirm("Are you sure you want to delete result")) {
            const docRef = firestore.collection('result').doc(resultId);
            docRef.delete().then(() => {
                console.log("Result Deleted");
            })
        } else {
            console.log("You pressed Cancel!");
        }
    }

    return (
        <div className="mdk-drawer-layout js-mdk-drawer-layout" data-push data-responsive-width="992px">
            <div className="mdk-drawer-layout__content page-content">
                <Header />
                <div className="pt-32pt">
                    <div className="container page__container d-flex flex-column flex-md-row align-items-center text-center text-sm-left">
                        <div className="flex d-flex flex-column flex-sm-row align-items-center">
                            <div className="mb-24pt mb-sm-0 mr-sm-24pt">
                                <h2 className="mb-0">Student Result</h2>
                                <ol className="breadcrumb p-0 m-0">
                                    <li className="breadcrumb-item"><a href="/admin-dashboard">Home</a></li>
                                    <li className="breadcrumb-item"><a href="/students">Students</a></li>
                                    <li className="breadcrumb-item active">
                                        Result
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Page Content */}
                <div className="container page__container page-section">
                    <div>
                        <div className="page-separator">
                            <div className="page-separator__text">Result Details</div>
                        </div>
                        <form onSubmit={handleSubmit(createNewResult)}>
                            {isErrorFlaq ? <Error message={errorMgs} /> : ""}
                            {isSuccessFlaq ? <Success message={successMgs} /> : ""}
                            <br />
                            <div className="col-md-8 pt-0">
                                <div className="form-group">
                                    <label className="form-label">Cohort</label>
                                    <select id="custom-select"
                                        class="form-control custom-select" {...register('resultCohort')} required>
                                        <SelectCohort />
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">Semester</label>
                                    <select id="custom-select" class="form-control custom-select" {...register('semester')} required>
                                        <option selected value="">Select Semester</option>
                                        <option value="I">I</option>
                                        <option value="II">II</option>
                                        <option value="III">III</option>
                                    </select>
                                </div>
                                <input type="hidden" defaultValue={`${params.studentId}`} {...register('studentId')} />
                            </div>
                            <button className={`btn btn-primary m-12pt ${isLoading ? "is-loading is-loading-sm disabled" : " "}`} type="submit">Create New Result</button>
                        </form>
                    </div>

                    <div className="mt-32pt">
                        <div className="page-separator">
                            <div className="page-separator__text">Results</div>
                        </div>
                        <div className="card mb-0">
                            <div className="table-responsive" data-toggle="lists" data-lists-sort-by="js-lists-values-employee-name" data-lists-values="[&quot;js-lists-values-employee-name&quot;, &quot;js-lists-values-employer-name&quot;, &quot;js-lists-values-projects&quot;, &quot;js-lists-values-activity&quot;, &quot;js-lists-values-earnings&quot;]">
                                <div className="card-header">
                                </div>
                                <table className="table mb-0 thead-border-top-0 table-nowrap">
                                    <thead>
                                        <tr>
                                            <th style={{ width: 7 }}>  </th>
                                            <th>Result Title</th>
                                            <th className="text-center" style={{ width: 37 }}> Semester </th>
                                            <th className="text-center" style={{ width: 37 }}> Status </th>
                                            <th style={{ width: 37 }}> Date Created </th>
                                            <th style={{ width: 37 }}>  </th>
                                            <th style={{ width: 37 }}>  </th>
                                        </tr>
                                    </thead>
                                    <tbody className="list" id="staff">
                                        {
                                            results.map((result) => {
                                                return <ResultList result={result} handleDelete={handleDelete.bind(this, result.id)} />
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
                {/* // END Page Content */}
                <Footer />
            </div>
            <Navbar />
        </div>
    );
}
export default Result;

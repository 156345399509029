import React, { useState, useEffect } from 'react';
import { firestore } from "../../firebase/config";
import { useParams } from 'react-router-dom';

const TableHeadColumn = (props) => {

    const resultId = useParams().id;
    const studentId = useParams().studentId;

    const [ result, setResult ] = useState("");
    const [ student, setStudent ] = useState("");

    useEffect(() => {
        let result;
        firestore
            .collection(`result`)
            .doc(studentId)
            .collection(`results`)
            .doc(resultId)
            .get().then((document) => {
                if (document.exists) {
                    setResult(document.data());
                }
            })

        const resultDocRef = firestore.collection(`result`)
            .doc(studentId)
            .collection(`results`)
            .doc(resultId).get().then((document) => {
                if (document.exists) {
                    setResult(document.data());
                }
            })

        let students;
        const docRef = firestore.collection('students').doc(studentId);
        docRef.get().then((document) => {
            if (document.exists) {
                setStudent(document.data());
            }
        })
    }, []);

    return (
        <>
            <tr>
                <th></th>
                <th colSpan={props.courseList.length + 1} className="text-center text-black-100 p-12pt">Student Evaluation Summary</th>
                <th></th>
            </tr>
            <tr>
                <th></th>
                <th colSpan={props.courseList.length + 1} className="text-center p-12pt">
                    <span className="text-black-50">Cohort: </span> <span>{result.resultCohort} </span>
                    <span className="ml-24pt text-black-50">Semester:  </span> <span>{result.semester} </span>
                </th>
                <th></th>
            </tr>
            <tr>
                <th></th>
                <th colSpan={props.courseList.length + 1} className="text-center p-12pt">
                    <span className="text-black-50">Student Name:</span> <span> {student.displayName}  </span>
                    <span className="ml-24pt text-black-50">Student Number:</span> <span>{student.studentNumber} </span>
                </th>
                <th></th>
            </tr>
            <tr>
                <th></th>
                <th className="text-center">
                    <div className="lh-1 d-flex flex-column text-black-100 my-2pt">
                        Courses/Tests
                    </div>
                </th>
                {
                    props.courseList.map((course, index) => {
                        return <th className="text-center" key={index}>
                            <div className="lh-1 d-flex flex-column text-black my-4pt">
                                {course.courseTitle}
                            </div>
                        </th>
                    })
                }
                <th></th>
            </tr>
        </>
    );
}

export default TableHeadColumn;

import React from 'react';
import { useParams } from 'react-router-dom';

const Home = () => {
    const studentId = useParams().studentId;

    return (
        <div className="mdk-header-layout__content page-content mt-64pt ">
            <div className="pt-32pt">
                <div className="container page__container d-flex flex-column flex-md-row align-items-center text-center text-sm-left">
                    <div className="flex d-flex flex-column flex-sm-row align-items-center mb-24pt mb-md-0">
                        <div className="mb-24pt mb-sm-0 mr-sm-24pt">
                            <h2 className="mb-0">Dashboard</h2>
                            <ol className="breadcrumb p-0 m-0">
                                <li className="breadcrumb-item"><a href={`/dashboard/${studentId}`}>Home</a></li>
                                <li className="breadcrumb-item active">
                                    Dashboard
                                </li>
                            </ol>
                        </div>
                    </div>
                    <div className="row" role="tablist">
                        <div className="col-auto">
                            <a href={`/results/${studentId}`} className="btn btn-outline-secondary">View Assessment Result</a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Home;

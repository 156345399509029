import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { resetPassword } from '../firebase/auth';
import Success from './Success'

const ForgotPassword = () => {

    const { register, handleSubmit, reset } = useForm();
    const [ isLoading, setIsLoading ] = useState(false);
    const [ mgs, setMgs ] = useState("");
    const [ isCompleteFlaq, setIsCompleteFlaq ] = useState(false);

    const sendResetPassword = async (data) => {
        setIsLoading(true);
        try {
            await resetPassword(data);
            setMgs("Reset successful ! Reset link has been sent to your mail");
            reset();
        }
        catch (error) {
            setMgs(error.message);
            console.log(error);
        }
        setIsCompleteFlaq(true)
        setIsLoading(false);
    };

    return (
        <div className="mdk-header-layout js-mdk-header-layout">
            <div className="bg-body mt-64pt pt-32pt pb-0pt pb-md-16pt text-center">
                <div className="container page__container">
                    <Link to="/"> <img className src="assets/images/logo/logo.png" alt="Behaviorprise College" /> </Link>
                </div>
            </div>
            <div className="mdk-header-layout__content page-content ">
                <div className="pt-8pt pt-sm-32pt pb-32pt">
                    <div className="container page__container col-md-5 p-0 mx-auto">
                        <div className="page-separator">
                            <div className="page-separator__text">Reset Password</div>
                        </div>
                        <form onSubmit={handleSubmit(sendResetPassword)}  >
                            {isCompleteFlaq ? <Success message={mgs} /> : ""}
                            <br />
                            <div className="form-group">
                                <label className="form-label">Email:</label>
                                <input type="text" className="form-control" placeholder="Your email address ..." {...register('emailAddress')} required />
                                <small className="form-text text-muted">We will email you with info on how to reset your password.</small>
                            </div>
                            <div className="text-center p-16pt mt-32pt">
                                <button className={`btn btn-primary ${isLoading ? "is-loading is-loading-sm disabled" : " "}`} type="submit">Reset Password</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="bg-white border-top-2 mt-auto">
                <div className="container page__container page-section d-flex flex-column">
                    <p className="mb-8pt d-flex">
                        <a href="https://behaviorprisecollege.ca/" target="_blank" rel="noreferrer" className="text-70 text-underline mr-8pt small">Visit College Website</a>
                    </p>
                    <p className="text-50 small mt-n1 mb-0">Copyright 2022 © All rights reserved.</p>
                </div>
            </div>
        </div>
    );
}

export default ForgotPassword;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { login } from '../firebase/auth';
import Error from './Error';
import { firestore } from "../firebase/config";

const Login = (props) => {

    const { register, handleSubmit, reset } = useForm();
    const [ isLoading, setIsLoading ] = useState(false);
    const [ errorMgs, setErrorMgs ] = useState("");
    const [ isErrorFlaq, setIsErrorFlaq ] = useState(false);

    const loginUser = async (data) => {
        setIsLoading(true);
        let loggedUser;
        try {
            loggedUser = await login(data);
            reset();
        }
        catch (error) {
            setErrorMgs(error.message);
            setIsErrorFlaq(true)
            console.log(error);
        }
        setIsLoading(false);
        if (loggedUser) {
            localStorage.setItem('auth', "loggedIn");
            firestore
                .collection(`students`)
                .doc(loggedUser.user.uid)
                .get().then((document) => {
                    if (document.exists) {
                        if (document.data().isAdmin == false) {
                            props.history.push(`/dashboard/${loggedUser.user.uid}`);
                        }
                    }
                    else {
                        firestore
                            .collection(`instructors`)
                            .doc(loggedUser.user.uid)
                            .get().then((document) => {
                                if (document.exists) {
                                    if (document.data().isAdmin == true && document.data().role == "instructor") {
                                        localStorage.setItem('role', "instructor");
                                    }
                                }
                                props.history.push(`/admin-dashboard`);
                            });
                    }
                })
        }
    };

    return (
        <div className="mdk-header-layout js-mdk-header-layout">
            <div className="bg-body mt-64pt pt-32pt pb-0pt pb-md-16pt text-center">
                <div className="container page__container">
                    <a href="/">
                        <img className src="assets/images/logo/logo.png" alt="Behaviorprise College" />
                    </a>
                </div>
            </div>
            <div className="mdk-header-layout__content page-content ">
                <div className="pt-8pt pt-sm-32pt pb-32pt">
                    <div className="container page__container">
                        <form onSubmit={handleSubmit(loginUser)} className="col-md-5 p-0 mx-auto">
                            {isErrorFlaq ? <Error message={errorMgs} /> : ""}
                            <br />
                            <div className="form-group">
                                <label className="form-label" htmlFor="email">Email:</label>
                                <input id="email" type="text" className="form-control" placeholder="Your email address ..." {...register('emailAddress')} required />
                            </div>
                            <div className="form-group">
                                <label className="form-label" htmlFor="password">Password:</label>
                                <input id="password" type="password" className="form-control" placeholder="Your password ..." {...register('password')} required />

                            </div>
                            <p className="text-right">
                                <Link to="/reset" classname="small">Forgot your password? </Link> </p>
                            <div className="text-center p-16pt">
                                <button className={`btn btn-primary ${isLoading ? "is-loading is-loading-sm disabled" : " "}`} type="submit">Click to Login</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="bg-white border-top-2 mt-auto">
                <div className="container page__container page-section d-flex flex-column">
                    <p className="mb-8pt d-flex">
                        <a href="https://behaviorprisecollege.ca/" target="_blank" rel="noreferrer" className="text-70 text-underline mr-8pt small">Visit College Website</a>
                    </p>
                    <p className="text-50 small mt-n1 mb-0">Copyright 2022 © All rights reserved.</p>
                </div>
            </div>
        </div>
    );
}

export default Login;

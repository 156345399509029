import React, { useState, useEffect } from 'react';
import { firestore } from "../../firebase/config";
import { Link, useParams } from 'react-router-dom';
import { logout } from '../../firebase/auth';
import { useHistory, Redirect } from 'react-router-dom';

const Header = (props) => {

    const history = useHistory();
    const studentId = useParams().studentId;
    const [ student, setStudent ] = useState("");

    useEffect(() => {
        firestore
            .collection(`students`)
            .doc(studentId)
            .get().then((document) => {
                if (document.exists) {
                    setStudent(document.data());
                }
            })
    }, []);

    const logoutUser = async () => {
        localStorage.clear();
        await logout();
        history.push('/');
    };

    const auth = localStorage.getItem('auth');

    if (auth !== "loggedIn") {
        return <Redirect to="/" />
    }


    return (
        <div id="header" className="mdk-header mdk-header--bg-primary bg-dark js-mdk-header mb-0" data-effects="parallax-background waterfall" data-fixed data-condenses>
            <div className="mdk-header__bg">
                <div className="mdk-header__bg-front" style={{ backgroundImage: 'url(/assests/images/1280_work-station-straight-on-view.jpg)' }} />
            </div>
            <div className="mdk-header__content justify-content-center">
                <div className="navbar navbar-expand pr-0 navbar-dark-pickled-bluewood navbar-shadow" id="default-navbar" data-primary>
                    <button className="navbar-toggler w-auto mr-16pt d-block rounded-0" type="button" data-toggle="sidebar">
                    </button>
                    <a href={`/dashboard/${studentId}`} className="navbar-brand mr-16pt">
                        <span className="avatar avatar-sm navbar-brand-icon mr-0 mr-lg-8pt">
                            <span className="avatar-title rounded bg-white"><img src="/assets/images/logo/logo_sm.png" alt="logo" className="img-fluid" /></span>
                        </span>
                        <span className="d-none d-lg-block" />
                    </a>
                    <span className="d-none d-md-flex align-items-center mr-16pt">
                        <span className="avatar avatar-sm mr-12pt">
                            <span className="avatar-title rounded navbar-avatar"><i className="material-icons">opacity</i></span>
                        </span>
                        <small className="flex d-flex flex-column">
                            <strong className="navbar-text-100">Hi {student.firstName}</strong>
                            <span className="navbar-text-50">{student.cohort}</span>
                        </small>
                    </span>
                    <span className="d-none d-md-flex align-items-center mr-16pt">
                        <span className="avatar avatar-sm mr-12pt">
                            <span className="avatar-title rounded navbar-avatar"><i className="material-icons">account_box</i></span>
                        </span>
                        <small className="flex d-flex flex-column  mr-16pt">
                            <strong className="navbar-text-100 ">Student Number</strong>
                            <span className="navbar-text-50">{student.studentNumber}</span>
                        </small>
                    </span>
                    <div className="flex" />
                    <div className="nav navbar-nav flex-nowrap d-flex mr-16pt">
                        <div className="nav-item dropdown">
                            <a href="#" className="nav-link d-flex align-items-center dropdown-toggle" data-toggle="dropdown" data-caret="false">
                                <span className="avatar avatar-sm mr-8pt2">
                                    <span className="avatar-title rounded-circle">
                                        <img src={student.profilePhotoUrl === "" ? "/assets/images/logo/user.png" : student.profilePhotoUrl}
                                            alt="people" width={100} className="avatar-img rounded-circle" />
                                    </span>
                                </span>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right">
                                <div className="dropdown-header"><strong>Account</strong></div>
                                <a className="dropdown-item" href={`/account/${studentId}`}>View Profile</a>
                                <a className="dropdown-item" href={`/results/${studentId}`}>Check Results</a>
                                <Link className="dropdown-item" onClick={logoutUser}>Logout</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Header;

import React, { useState, useEffect } from 'react';
import { firestore } from "../../firebase/config"

const SelectCourse = () => {

    const [ courseList, setCourseList ] = useState([]);

    useEffect(() => {
        firestore
            .collection('course')
            .onSnapshot(snap => {
                const course = snap.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setCourseList(course);
            });
    }, []);

    if (!courseList) {
        return (<div></div>)
    }
    return (
        <>
            <option selected value="">Select Course</option>
            {
                courseList.map((course, index) => {
                    return (
                        <option value={course.courseTitle} key={index}>{course.courseTitle}</option>)
                })
            }
        </>
    )
}

export default SelectCourse;

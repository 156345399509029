import React from 'react';
import Success from '../Success';

const ResultList = (props) => {
    return (
        <tr>
            <td> </td>
            <td>
                <div className="flex">
                    <a className="card-title js-lists-values-course" href>{props.result.resultCohort}</a>
                </div>
            </td>
            <td className="text-50 js-lists-values-activity small">{props.result.semester}</td>
            <td>
                <a href className={`chip  ${props.result.status == 'published' ? "chip-outline-success" : "chip-outline-danger"}`}>{props.result.status}</a>
            </td>
            <td className="text-50 js-lists-values-activity small">{props.result.dateCreated}</td>
            <td>
                {
                    props.result.status != 'published' ? <a href={`/compute/${props.result.studentId}/${props.result.id}`} className="chip chip-outline-primary">Compute</a> :
                        <a href={`/result-details/${props.result.studentId}/${props.result.id}`} className="chip chip-outline-primary">View Result</a>
                }
            </td>
            <td>
                <a href className="chip chip-outline-secondary" onClick={props.handleDelete}>Delete Result</a>
            </td>
        </tr>
    );
}

export default ResultList;

import React from 'react';

const CohortList = (props) => {
    return (
        <tr>
            <td></td>
            <td>
                <div className="flex">
                    <a className="card-title js-lists-values-course" href>{props.cohort.cohortTitle}</a>
                </div>
            </td>
            <td className="text-50 js-lists-values-activity small">{props.cohort.cohortStartDate}</td>
            <td className="text-50 js-lists-values-activity small">{props.cohort.dateCreated}</td>
            {/* <td>
                {props.active == true ?
                    <a href className="chip chip-outline-success">active</a> : ``}
            </td> */}
            {/* <td>
                <a href className="chip chip-outline-danger" onClick={props.handleEndCohort}>End Cohort</a>
            </td> */}
            <td>
                <a href className="chip chip-outline-secondary" onClick={props.handleDelete}>Delete Cohort</a>
            </td>
        </tr>
    );
}

export default CohortList;

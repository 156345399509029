import React, { useState, useEffect } from 'react';
import { firestore } from "../../firebase/config"

const SelectProgram = () => {

    const [ programList, setProgramList ] = useState([]);

    useEffect(() => {
        firestore
            .collection('program')
            .onSnapshot(snap => {
                const programs = snap.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setProgramList(programs);
            });
    }, []);

    if (!programList) {
        return (<div></div>)
    }
    return (
        <>
            <option selected value="">Select Program</option>
            {
                programList.map((program, index) => {
                    return (
                        <option value={program.programTitle} key={index}>{program.programTitle}</option>)
                })
            }
        </>
    )
}

export default SelectProgram;

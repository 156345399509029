import React from 'react';

const Success = (props) => {
    return (
        <div className="alert alert-soft-success alert-dismissible fade show mb-0" role="alert">
            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
            <div className="d-flex flex-wrap align-items-start">
                <div className="mr-8pt">
                    <i className="material-icons">check</i>
                </div>
                <div className="flex" style={{ minWidth: 180 }}>
                    <small className="text-black-100">
                        <strong>{props.message}</strong>
                    </small>
                </div>
            </div>
        </div>
    );
}

export default Success;

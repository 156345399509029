import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import Header from './Header';
import Footer from './Footer';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { createResultScore, createCourseResult, publishStudentResult } from '../../firebase/admin';
import Error from '../Error';
import Success from '../Success';
import SelectCourse from './SelectCourse';
import SelectAssesment from './SelectAssesment';
import ScoreList from './ScoreList';
import { firestore } from "../../firebase/config";

const Compute = (props) => {

    const resultId = useParams().id;
    const studentId = useParams().studentId;

    const [ scoreForm, setScoreForm ] = useState([
        { assessmentType: "", testScore: "" }
    ])
    const [ courseTitle, setCourseTitle ] = useState("")
    const [ courseSemesterTotalObtainable, setCourseSemesterTotalObtainable ] = useState(0)
    const [ courseResult, setCourseResult ] = useState([])

    const { register, handleSubmit, reset } = useForm();
    const [ isLoading, setIsLoading ] = useState(false);
    const [ errorMgs, setErrorMgs ] = useState("");
    const [ isErrorFlaq, setIsErrorFlaq ] = useState(false);
    const [ successMgs, setSuccessMgs ] = useState("");
    const [ isSuccessFlaq, setIsSuccessFlaq ] = useState(false);

    const [ isPublishLoading, setIsPublishLoading ] = useState(false);
    const [ publishErrorMgs, setPublishErrorMgs ] = useState("");
    const [ isPublishErrorFlaq, setIsPublishErrorFlaq ] = useState(false);
    const [ publishSuccessMgs, setPublishSuccessMgs ] = useState("");
    const [ isPublishSuccessFlaq, setIsPublishSuccessFlaq ] = useState(false);

    const handleCourseTitleChange = (event) => {
        setCourseTitle(event.target.value);
    }

    const handleCourseSemesterTotalObtainableChange = (event) => {
        setCourseSemesterTotalObtainable(event.target.value);
    }

    const handleFormChange = (index, event) => {
        let data = [ ...scoreForm ];
        data[ index ][ event.target.name ] = event.target.value;
        setScoreForm(data);
    }

    const addFields = () => {
        let newfield = { assessmentType: "", testScore: "" }
        setScoreForm([ ...scoreForm, newfield ])
    }

    const removeFields = (index) => {
        let data = [ ...scoreForm ];
        data.splice(index, 1)
        setScoreForm(data)
    }

    const createNewResultScore = async (data) => {
        data.preventDefault();

        if (courseSemesterTotalObtainable < 1) {
            setIsErrorFlaq(true)
            setErrorMgs("Total score must be higher than 0");
            return
        }

        var totalScore = 0;
        scoreForm.map(async ({ assessmentType, testScore }) => {
            console.log("test score :", testScore);
            totalScore = (totalScore + parseInt(testScore));
        })
        var totalScorePercentage = (totalScore / courseSemesterTotalObtainable) * 100;

        setIsLoading(true);

        if (totalScore > courseSemesterTotalObtainable) {
            setIsLoading(false);
            setIsErrorFlaq(true)
            setErrorMgs("Total score is higher than obtainable score");
            return
        }

        try {
            setIsErrorFlaq(false)
            let newCourseResult = await createCourseResult(resultId, courseTitle, totalScore, totalScorePercentage)
            if (newCourseResult)
                scoreForm.map(async ({ assessmentType, testScore }) => {
                    await createResultScore(resultId, newCourseResult.id, courseTitle, assessmentType, testScore);
                })
            setScoreForm([ { assessmentType: "", testScore: "" } ])
            setCourseTitle("")
            setCourseSemesterTotalObtainable(0)
            reset();
            setSuccessMgs("Course Score Recorded Successfully");
            setIsSuccessFlaq(true)
            setIsLoading(false);
        }
        catch (error) {
            setErrorMgs(error.message);
            setIsErrorFlaq(true)
            console.log(error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        firestore
            .collection('courseResult')
            .doc(resultId)
            .collection('courses')
            .onSnapshot(snap => {
                const courseResultList = snap.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                console.log("result1: ", courseResultList)
                setCourseResult(courseResultList);
            });
    }, []);

    const handleDelete = (courseResultId) => {
        if (window.confirm("Are you sure you want to delete course result")) {
            const docRef = firestore.collection('courseResult')
                .doc(resultId)
                .collection("courses")
                .doc(courseResultId);
            docRef.delete().then(() => {
                console.log("Course Result Deleted");
            })
        } else {
            console.log("You pressed Cancel!");
        }
    }

    const publishResult = async (studentId, resultId) => {
        setIsPublishLoading(true);
        try {
            let published = await publishStudentResult(studentId, resultId)
            console.log("You!");
            setPublishSuccessMgs("Result Recorded and Successfully Published");
            setIsPublishSuccessFlaq(true)
            setIsPublishLoading(false);
        }
        catch (error) {
            setPublishErrorMgs(error.message);
            setIsPublishErrorFlaq(true)
            console.log(error);
            setIsPublishLoading(false);
        }
    }

    return (
        <div className="mdk-drawer-layout js-mdk-drawer-layout" data-push data-responsive-width="992px">
            <div className="mdk-drawer-layout__content page-content">
                <Header />

                <div className="container page__container align-items-center text-center text-sm-left">
                    <div className="mt-24pt">
                        {isPublishErrorFlaq ? <Error message={publishErrorMgs} /> : ""}
                        {isPublishSuccessFlaq ? <Success message={publishSuccessMgs} /> : ""}
                    </div>
                </div>

                <div className="pt-24pt">
                    <div className="container page__container d-flex flex-column flex-md-row align-items-center text-center text-sm-left">
                        <div className="flex d-flex flex-column flex-sm-row align-items-center">
                            <div className="mb-24pt mb-sm-0 mr-sm-24pt">
                                <h2 className="mb-0">Compute Result Score</h2>
                                <ol className="breadcrumb pt-12pt m-0">
                                    <li className="breadcrumb-item"><a href="/admin-dashboard">Home</a></li>
                                    <li className="breadcrumb-item"><a href onClick={() => { props.history.goBack() }}>Results</a></li>
                                    <li className="breadcrumb-item active">
                                        Score
                                    </li>
                                </ol>
                            </div>
                        </div>

                        <div class="row"
                            role="tablist">
                            <div class="col-auto">
                                {
                                    courseResult.length > 0 ?
                                        <span class={`btn btn-outline-primary ${isPublishLoading ? "is-loading is-loading-sm disabled" : " "}`} onClick={() => { publishResult(studentId, resultId) }}>Publish Result</span> :
                                        ""
                                }
                            </div>
                        </div>
                    </div>
                </div>

                {/* Page Content */}
                <div className="container page__container page-section">
                    <div>
                        <div className="page-separator">
                            <div className="page-separator__text">Assessment Information</div>
                        </div>

                        <div className="d-flex align-items-center mb-lg-32pt">
                            {
                                courseResult.map((course) => {
                                    return <ScoreList course={course} handleDelete={handleDelete.bind(this, course.id)} key={course.id} />
                                })
                            }
                        </div>

                        <form onSubmit={createNewResultScore}>
                            {isErrorFlaq ? <Error message={errorMgs} /> : ""}
                            {isSuccessFlaq ? <Success message={successMgs} /> : ""}
                            <br />
                            <div className="col-md-8  mb-24pt">
                                <div className="form-group">
                                    <label className="form-label">Course Title</label>
                                    <select id="custom-select"
                                        class="form-control custom-select" name="courseTitle" value={courseTitle} onChange={event => handleCourseTitleChange(event)} required>
                                        <SelectCourse />
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">Course Semester Total Obtainable Score</label>
                                    <input type="number" className="form-control" name="courseSemesterObtainableTotal" value={courseSemesterTotalObtainable} placeholder="Course Semester Total ..." onChange={event => handleCourseSemesterTotalObtainableChange(event)} required />
                                </div>

                                <div className="form-group"  >
                                    <label className="form-label"> Assessment / Test  </label>
                                    {
                                        scoreForm.map((input, index) => {
                                            return (
                                                <div className="form-row mt-12pt" key={index}>
                                                    <div className="col-md-8 mb-16pt mb-sm-0">
                                                        <select id="custom-select"
                                                            class="form-control custom-select" name="assessmentType" value={input.assessmentType} onChange={event => handleFormChange(index, event)} required>
                                                            <SelectAssesment />
                                                        </select>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <input type="number" className="form-control" name="testScore" defaultValue="" placeholder="Enter Score ..." value={input.testScore} onChange={event => handleFormChange(index, event)} required />
                                                    </div>
                                                    <div className="col-md-1">
                                                        {
                                                            index == 0 ?
                                                                <span className="d-flex flex-column border-1 rounded bg-alt px-3pt py-8pt lh-1 text-center ml-4pt" onClick={addFields} style={{ cursor: 'pointer' }}>
                                                                    <i className="material-icons text-50 icon-16pt">add</i>
                                                                </span> :

                                                                <span className="d-flex flex-column border-1 rounded bg-alt px-3pt py-8pt lh-1 text-center ml-8pt" onClick={() => removeFields(index)} style={{ cursor: 'pointer' }}>
                                                                    <i className="material-icons text-50 icon-16pt">close</i>
                                                                </span>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <button className={`btn btn-primary m-12pt ${isLoading ? "is-loading is-loading-sm disabled" : " "}`} type="submit">Record New Score </button>
                        </form>
                    </div>

                </div>
                {/* // END Page Content */}
                <Footer />
            </div>
            <Navbar />
        </div>
    );
}

export default Compute;

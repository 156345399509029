import React, { useState, useEffect } from 'react';
import { firestore } from "../../firebase/config";
import Navbar from './Navbar';
import Header from './Header';
import Footer from './Footer';
import { useParams } from 'react-router-dom';
import { uploadImage, updateStudentProfile } from "../../firebase/student";

const Account = () => {

    const studentId = useParams().studentId;
    const [ student, setStudent ] = useState("");
    const [ isLoading, setIsLoading ] = useState(false);
    const [ imageUrl, setImageUrl ] = useState("");

    useEffect(() => {
        firestore
            .collection(`students`)
            .doc(studentId)
            .get().then((document) => {
                if (document.exists) {
                    setStudent(document.data());
                    console.log("image :", document.data())
                    setImageUrl(document.data().profilePhotoUrl)
                }
            })
    }, []);
    const onChange = async (e) => {
        setIsLoading(true)
        const ref = await uploadImage(studentId, e.target.files[ 0 ]);
        const downloadUrl = await ref.getDownloadURL();
        setImageUrl(downloadUrl);
        await updateStudentProfile(studentId, downloadUrl);
        setIsLoading(false)
        console.log("use this to download: " + downloadUrl);
        console.log(e.target.files);
    };

    return (
        <div>
            <div className="mdk-header-layout js-mdk-header-layout">
                <Header showExtraHeader={false} />
                <div className="pt-64pt">
                    <div className="container page__container d-flex flex-column flex-md-row align-items-center text-center text-sm-left mt-32pt">
                        <div className="flex d-flex flex-column flex-sm-row align-items-center mb-24pt mb-md-0">
                            <div className="mb-24pt mb-sm-0 mr-sm-24pt">
                                <h2 className="mb-0">Dashboard</h2>
                                <ol className="breadcrumb p-0 m-0">
                                    <li className="breadcrumb-item"><a href={`/dashboard/${studentId}`}>Home</a></li>
                                    <li className="breadcrumb-item active">
                                        Dashboard
                                    </li>
                                </ol>
                            </div>
                        </div>
                        <div className="row" role="tablist">
                            <div className="col-auto">
                                <a href={`/results/${studentId}`} className="btn btn-outline-secondary">View Assessment Result</a>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Page Content */}
                <div className="container page__container page-section">
                    <div>
                        <div className="page-separator">
                            <div className="page-separator__text">Personal Information</div>
                        </div>
                        <div className="col-md-8 mb-24pt">
                            <div className="form-group">
                                <label className="form-label">Your photo</label>
                                <div className="media align-items-center">

                                    {
                                        imageUrl === "" ?
                                            <>
                                                <a href=""
                                                    class="media-left mr-16pt">
                                                    <img src="/assets/images/logo/user.png"
                                                        alt="people"
                                                        width="56"
                                                        class="rounded-circle" />
                                                </a>
                                                <div className="media-body">
                                                    {
                                                        isLoading ?
                                                            <span className="is-loading is-loading-lg pl-16pt"></span> :
                                                            <div className="custom-file">
                                                                <input type="file" className="custom-file-input" id="inputGroupFile01" accept="image/png, image/jpeg" onChange={onChange} />
                                                                <label className="custom-file-label" htmlFor="inputGroupFile01">Choose file</label>
                                                            </div>
                                                    }
                                                </div>
                                            </> :
                                            <div className="avatar avatar-xl">
                                                <img src={imageUrl === "" ? "/assets/images/logo/user.png" : imageUrl}
                                                    alt="people" width={100} className="avatar-img rounded-circle" />
                                            </div>
                                    }

                                </div>
                            </div>
                        </div>


                        <form>
                            <div className="col-md-8 mb-24pt">
                                <div className="form-group">
                                    <label className="form-label">Email address</label>
                                    <input type="email" className="form-control" defaultValue={student.emailAddress} placeholder="Enter email address ..." disabled />
                                </div>
                                <div className="form-group">
                                    <label className="form-label">Last name</label>
                                    <input type="text" className="form-control" defaultValue={student.lastName} placeholder="Enter last name ..." disabled />
                                </div>
                                <div className="form-group">
                                    <label className="form-label">First name</label>
                                    <input type="text" className="form-control" defaultValue={student.firstName} placeholder="Enter first name ..." disabled />
                                </div>
                                <div className="form-group">
                                    <label className="form-label">Student Number</label>
                                    <input type="text" className="form-control" defaultValue={student.studentNumber} placeholder="Enter student number ..." disabled />
                                </div>
                                <div className="form-group">
                                    <label className="form-label">Gender</label>
                                    <select id="custom-select" class="form-control custom-select" required>
                                        <option selected value={student.gender}>{student.gender}</option>
                                    </select>
                                </div>
                            </div>


                            <div className="page-separator">
                                <div className="page-separator__text">Program Information</div>
                            </div>

                            <div className="col-md-8 pt-12pt">
                                <div className="form-group">
                                    <label className="form-label">Cohort</label>
                                    <select id="custom-select"
                                        class="form-control custom-select" >
                                        <option selected value={student.cohort}>{student.cohort}</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">Program</label>
                                    <select id="custom-select"
                                        class="form-control custom-select">
                                        <option selected value={student.program}>{student.program}</option>
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
                {/* // END Page Content */}
                <Footer />
            </div>
            <Navbar />
        </div>
    );
}

export default Account;

import React from 'react';
import Header from './Header';
import Navbar from './Navbar';
import Footer from './Footer'
import Home from './Home';


const ChangePassword = () => {
    return (
        <div>
            <div className="mdk-header-layout js-mdk-header-layout">
                <Header showExtraHeader={false} />
                <Home />
                <Footer />
            </div>
            <Navbar />
        </div>
    );
}

export default ChangePassword;

import React, { useState, useEffect } from 'react';
import { firestore } from "../../firebase/config"

const SelectCohort = () => {

    const [ cohortList, setCohortList ] = useState([]);

    useEffect(() => {
        firestore
            .collection('cohort')
            .onSnapshot(snap => {
                const cohorts = snap.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setCohortList(cohorts);
            });
    }, []);

    if (!cohortList) {
        return (<div></div>)
    }
    return (
        <>
            <option selected value="">Select Cohort</option>
            {
                cohortList.map((cohort, index) => {
                    return (
                        <option value={cohort.cohortTitle} key={index}>{cohort.cohortTitle}</option>)
                })
            }
        </>
    )
}

export default SelectCohort;

import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import Header from './Header';
import Footer from './Footer';
import 'firebase/auth';
import InstructorList from './InstructorList';
import { firestore } from "../../firebase/config";

const Instructors = () => {
    const [ students, setStudent ] = useState([]);

    useEffect(() => {
        firestore
            .collection('instructors')
            .onSnapshot(snap => {
                const student = snap.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setStudent(student);
            });
    }, []);

    const handleDelete = (studentId) => {
        if (window.confirm("Are you sure you want to delete instructor profile")) {
            const docRef = firestore.collection('students').doc(studentId);
            docRef.delete().then(() => {
                console.log("Student Deleted");
            })

        } else {
            console.log("You pressed Cancel!");
        }
    }

    return (
        <div className="mdk-drawer-layout js-mdk-drawer-layout" data-push data-responsive-width="992px">
            <div className="mdk-drawer-layout__content page-content">
                <Header />
                <div className="pt-32pt">
                    <div className="container page__container d-flex flex-column flex-md-row align-items-center text-center text-sm-left">
                        <div className="flex d-flex flex-column flex-sm-row align-items-center">
                            <div className="mb-24pt mb-sm-0 mr-sm-24pt">
                                <h2 className="mb-0">Instructors</h2>
                                <ol className="breadcrumb p-0 m-0">
                                    <li className="breadcrumb-item"><a href="admin-dashboard">Home</a></li>
                                    <li className="breadcrumb-item active">
                                        Instructor
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Page Content */}
                <div className="container page__container page-section">

                    <div>
                        <div className="page-separator">
                            <div className="page-separator__text">Registered Instructor</div>
                        </div>
                        <div className="card mb-0">
                            <div className="table-responsive" data-toggle="lists" data-lists-sort-by="js-lists-values-employee-name" data-lists-values="[&quot;js-lists-values-employee-name&quot;, &quot;js-lists-values-employer-name&quot;, &quot;js-lists-values-projects&quot;, &quot;js-lists-values-activity&quot;, &quot;js-lists-values-earnings&quot;]">
                                <div className="card-header">
                                    <div className="ml-auto mb-2 mb-sm-0 custom-control-inline mr-0">
                                        <a href="instructor" className="chip chip-outline-primary">
                                            <span class="material-icons sidebar-menu-icon sidebar-menu-icon--left">account_box</span>
                                            Register New Instructor</a>
                                    </div>
                                </div>
                                <table className="table mb-0 thead-border-top-0 table-nowrap">
                                    <thead>
                                        <tr>
                                            <th>Fullname </th>
                                            <th> Role </th>
                                            <th style={{ width: 24 }} className="pl-0">  </th>
                                        </tr>
                                    </thead>
                                    <tbody className="list" id="staff">
                                        {
                                            students.map((student) => {
                                                return <InstructorList student={student} handleDelete={handleDelete.bind(this, student.id)} />
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>

                            <div className="card-footer p-8pt">
                                <ul className="pagination justify-content-start pagination-xsm m-0">
                                    <li className="page-item disabled">
                                        <a className="page-link" href="#" aria-label="Previous">
                                            <span aria-hidden="true" className="material-icons">chevron_left</span>
                                            <span>Prev</span>
                                        </a>
                                    </li>
                                    {/* <li className="page-item">
                                        <a className="page-link" href="#" aria-label="Page 1">
                                            <span>1</span>
                                        </a>
                                    </li>*/}
                                    <li className="page-item disabled">
                                        <a className="page-link" href="#" aria-label="Next">
                                            <span>Next</span>
                                            <span aria-hidden="true" className="material-icons">chevron_right</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>


                </div>
                {/* // END Page Content */}
                <Footer />
            </div>
            <Navbar />
        </div>
    );
}

export default Instructors;

import firebase from 'firebase/app';
import { firestore, storage } from "./config"


export const uploadImage = (studentId, file) => {
    return new Promise((resolve, reject) => {
        //create file reference
        const filePath = `student/${studentId}/profile`;
        const fileRef = storage.ref().child(filePath);
        ///upload task
        const uploadTask = fileRef.put(file);
        uploadTask.on('state_changed', null, (error) => reject(error), () => {
            resolve(uploadTask.snapshot.ref)
        })
    })
}

export const updateStudentProfile = async (studentId, profilePhotoUrl) => {
    const docRef = firestore.doc(`/students/${studentId}`);
    return docRef.update({
        profilePhotoUrl: profilePhotoUrl,
    });
}

import React, { useState, useEffect } from 'react';
import { firestore } from "../../firebase/config";

const Statistics = () => {

    const [ students, setStudents ] = useState([]);
    const [ programs, setPrograms ] = useState([]);
    const [ courses, setCourses ] = useState([]);

    useEffect(() => {
        firestore
            .collection('students')
            .onSnapshot(snap => {
                const student = snap.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setStudents(student);
            });

        firestore
            .collection('program')
            .onSnapshot(snap => {
                const program = snap.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setPrograms(program);
            });

        firestore
            .collection('course')
            .onSnapshot(snap => {
                const course = snap.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setCourses(course);
            });
    }, []);



    return (
        <div className="row card-group-row mb-lg-8pt">
            <div className="col-lg-4 col-md-6 card-group-row__col">
                <div className="card card-group-row__card p-relative o-hidden">
                    <div className="card-body d-flex flex-row align-items-center">
                        <div className="flex">
                            <p className="card-title d-flex align-items-center">
                                <strong>Students</strong>
                                <span className="text-20 ml-8pt">Total</span>
                            </p>
                            <span className="h2 m-0">{students.length}</span>
                        </div>
                        <i className="material-icons icon-32pt text-20 ml-8pt">perm_identity</i>
                    </div>
                    <div className="progress" style={{ height: 3 }}>
                        <div className="progress-bar bg-primary" role="progressbar" style={{ width: '100%' }} aria-valuenow={25} aria-valuemin={0} aria-valuemax={100} />
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 card-group-row__col">
                <div className="card card-group-row__card p-relative o-hidden">
                    <div className="card-body d-flex flex-row align-items-center">
                        <div className="flex">
                            <p className="card-title d-flex align-items-center">
                                <strong>Programs</strong>
                                <span className="text-20 ml-8pt">Total</span>
                            </p>
                            <span className="h2 m-0">{programs.length}</span>
                        </div>
                        <i className="material-icons icon-32pt text-20 ml-8pt">school</i>
                    </div>
                    <div className="progress" style={{ height: 3 }}>
                        <div className="progress-bar" role="progressbar" style={{ width: '100%' }} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100} />
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 card-group-row__col">
                <div className="card card-group-row__card p-relative o-hidden">
                    <div className="card-body d-flex flex-row align-items-center">
                        <div className="flex">
                            <p className="card-title d-flex align-items-center">
                                <strong>Courses</strong>
                                <span className="text-20 ml-8pt">Total</span>
                            </p>
                            <span className="h2 m-0">{courses.length}</span>
                        </div>
                        <i className="material-icons icon-32pt text-20 ml-8pt">content_copy</i>
                    </div>
                    <div className="progress" style={{ height: 3 }}>
                        <div className="progress-bar" role="progressbar" style={{ width: '100%' }} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Statistics;

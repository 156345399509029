import React from 'react';
import Navbar from './Navbar';
import Header from './Header';
import Footer from './Footer';
import TimetableList from './TimetableList';


const Timetables = () => {
    return (
        <div className="mdk-drawer-layout js-mdk-drawer-layout" data-push data-responsive-width="992px">
            <div className="mdk-drawer-layout__content page-content">
                <Header />
                <div className="pt-32pt">
                    <div className="container page__container d-flex flex-column flex-md-row align-items-center text-center text-sm-left">
                        <div className="flex d-flex flex-column flex-sm-row align-items-center">
                            <div className="mb-24pt mb-sm-0 mr-sm-24pt">
                                <h2 className="mb-0">Timetables</h2>
                                <ol className="breadcrumb p-0 m-0">
                                    <li className="breadcrumb-item"><a href="admin-dashboard">Home</a></li>
                                    <li className="breadcrumb-item active">
                                        Timetables
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Page Content */}
                <div className="container page__container page-section">

                    <div>
                        <div className="page-separator">
                            <div className="page-separator__text">TimetableList</div>
                        </div>
                        <div className="card mb-0">
                            <div className="table-responsive" data-toggle="lists" data-lists-sort-by="js-lists-values-employee-name" data-lists-values="[&quot;js-lists-values-employee-name&quot;, &quot;js-lists-values-employer-name&quot;, &quot;js-lists-values-projects&quot;, &quot;js-lists-values-activity&quot;, &quot;js-lists-values-earnings&quot;]">
                                <div className="card-header">
                                    <div className="ml-auto mb-2 mb-sm-0 custom-control-inline mr-0">
                                        <a href="student" className="chip chip-outline-primary">
                                            <span class="material-icons sidebar-menu-icon sidebar-menu-icon--left">access_time</span>
                                            Create New Timetable</a>
                                    </div>
                                </div>
                                <table className="table mb-0 thead-border-top-0 table-nowrap">
                                    <thead>
                                        <tr>
                                            <th style={{ width: 7 }}>  </th>
                                            <th>Cohort Title</th>
                                            <th className="text-center" style={{ width: 51 }}>Start Date </th>
                                            <th className="text-center" style={{ width: 51 }}>Semester </th>
                                            <th style={{ width: 51 }}>  Created </th>
                                            <th style={{ width: 37 }}> Status </th>
                                            <th style={{ width: 37 }}>  </th>
                                            <th style={{ width: 37 }}>  </th>
                                        </tr>
                                    </thead>
                                    <tbody className="list" id="staff">
                                        <TimetableList />
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>

                </div>
                {/* // END Page Content */}
                <Footer />
            </div>
            <Navbar />
        </div>
    );
}

export default Timetables;

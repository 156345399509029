import React, { useState, useEffect } from 'react';
import Header from './Header';
import Navbar from './Navbar';
import Footer from './Footer'
import { useParams } from 'react-router-dom';
import { firestore } from "../../firebase/config";
import ResultList from './ResultList'


const Results = () => {

    const studentId = useParams().studentId;
    const [ results, setResult ] = useState([]);


    useEffect(() => {
        firestore
            .collection('result')
            .doc(studentId)
            .collection('results').where('status', '==', 'published')
            .onSnapshot(snap => {
                const resultList = snap.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setResult(resultList);
            });
    }, []);

    return (
        <div>
            <div className="mdk-header-layout js-mdk-header-layout">
                <Header />
                <div className="mdk-header-layout__content page-content mt-64pt ">
                    <div className="pt-32pt">
                        <div className="container page__container d-flex flex-column flex-md-row align-items-center text-center text-sm-left">
                            <div className="flex d-flex flex-column flex-sm-row align-items-center mb-24pt mb-md-0">
                                <div className="mb-24pt mb-sm-0 mr-sm-24pt">
                                    <h2 className="mb-0">Result</h2>
                                    <ol className="breadcrumb p-0 m-0">
                                        <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                                        <li className="breadcrumb-item active">
                                            Result
                                        </li>
                                    </ol>
                                </div>
                            </div>
                            <div className="row" role="tablist">
                                <div className="col-auto">
                                    <a href={`/dashboard/${studentId}`} className="btn btn-outline-secondary">Homepage</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container page__container">
                        <div className="page-section">
                            <div className="row mb-lg-8pt">
                                <div className="col-lg-12">
                                    <div className="page-separator">
                                        <div className="page-separator__text">Your Results</div>
                                    </div>

                                    <div className="card mb-0">
                                        <div className="table-responsive" data-toggle="lists" data-lists-sort-by="js-lists-values-employee-name" data-lists-values="[&quot;js-lists-values-employee-name&quot;, &quot;js-lists-values-employer-name&quot;, &quot;js-lists-values-projects&quot;, &quot;js-lists-values-activity&quot;, &quot;js-lists-values-earnings&quot;]">
                                            <div className="card-header">
                                            </div>
                                            <table className="table mb-0 thead-border-top-0 table-nowrap">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: 7 }}>  </th>
                                                        <th>Result Year</th>
                                                        <th className="text-center" style={{ width: 37 }}> Semester </th>
                                                        <th style={{ width: 37 }}> Date Created </th>
                                                        <th style={{ width: 37 }}>  </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list" id="staff">
                                                    {
                                                        results.length > 0 ?
                                                            results.map((result) => {
                                                                return <ResultList result={result} key={result.id} />
                                                            })
                                                            :
                                                            <tr>
                                                                <td colSpan={5} className="text-center">
                                                                    <p class="lead measure-hero-lead mx-auto mx-md-0 text-black text-shadow my-4pt">You don't have any published result</p>
                                                                </td>
                                                            </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>
            <Navbar />
        </div>
    );
}

export default Results;

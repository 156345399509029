import React from 'react';

const AssessmentList = (props) => {
    return (
        <tr>
            <td></td>
            <td>
                <div className="flex">
                    <a className="card-title js-lists-values-course" href>{props.assessment.assessmentTitle}</a>
                </div>
            </td>
            <td className="text-50 js-lists-values-activity small">{props.assessment.dateCreated}</td>
            <td>
                <a href className="chip chip-outline-secondary" onClick={props.handleDelete}>Delete Test</a>
            </td>
        </tr>
    );
}

export default AssessmentList;

import React, { useState } from 'react';
import Navbar from './Navbar';
import Header from './Header';
import Footer from './Footer';
import { useForm } from 'react-hook-form';
import { createAssessment } from '../../firebase/admin';
import Error from '../Error';
import Success from '../Success';

const Assessment = () => {

    const { register, handleSubmit, reset } = useForm();
    const [ isLoading, setIsLoading ] = useState(false);
    const [ errorMgs, setErrorMgs ] = useState("");
    const [ isErrorFlaq, setIsErrorFlaq ] = useState(false);
    const [ successMgs, setSuccessMgs ] = useState("");
    const [ isSuccessFlaq, setIsSuccessFlaq ] = useState(false);

    const createNewAssessment = async (data) => {
        setIsLoading(true);
        let assessment;
        try {
            assessment = await createAssessment(data);
            reset();
            setSuccessMgs("Assessment Created Successfully");
            setIsSuccessFlaq(true)
            setIsLoading(false);
        }
        catch (error) {
            setErrorMgs(error.message);
            setIsErrorFlaq(true)
            console.log(error);
            setIsLoading(false);
        }
    };

    return (
        <div className="mdk-drawer-layout js-mdk-drawer-layout" data-push data-responsive-width="992px">
            <div className="mdk-drawer-layout__content page-content">
                <Header />
                <div className="pt-32pt">
                    <div className="container page__container d-flex flex-column flex-md-row align-items-center text-center text-sm-left">
                        <div className="flex d-flex flex-column flex-sm-row align-items-center">
                            <div className="mb-24pt mb-sm-0 mr-sm-24pt">
                                <h2 className="mb-0">Create Assessment / Test</h2>
                                <ol className="breadcrumb pt-12pt m-0">
                                    <li className="breadcrumb-item"><a href="admin-dashboard">Home</a></li>
                                    <li className="breadcrumb-item"><a href="assessments">Assessments</a></li>
                                    <li className="breadcrumb-item active">
                                        Assessment
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Page Content */}
                <div className="container page__container page-section">
                    <div>
                        <div className="page-separator">
                            <div className="page-separator__text">Assessment Information</div>
                        </div>
                        <form onSubmit={handleSubmit(createNewAssessment)}>
                            {isErrorFlaq ? <Error message={errorMgs} /> : ""}
                            {isSuccessFlaq ? <Success message={successMgs} /> : ""}
                            <br />
                            <div className="col-md-8 pt-12 mb-24pt mt-12pt">
                                <div className="form-group">
                                    <label className="form-label">Assessment Title</label>
                                    <input type="text" className="form-control" defaultValue="" placeholder="Enter assessment title ..." {...register('assessmentTitle')} required />
                                </div>
                            </div>
                            <button className={`btn btn-primary m-12pt ${isLoading ? "is-loading is-loading-sm disabled" : " "}`} type="submit">Create New Assessment</button>
                        </form>
                    </div>

                </div>
                {/* // END Page Content */}
                <Footer />
            </div>
            <Navbar />
        </div>
    );
}

export default Assessment;

import React from 'react';

const Footer = () => {
    return (
        <div className="bg-white border-top-2 mt-auto">
            <div className="container page__container page-section d-flex flex-column">
                <p className="text-70 brand mb-24pt">
                    <img className="brand-icon" src="/assets/images/logo/logo_text.png" width={150} alt="Behaviorprise College" />
                </p>
                <p className="mb-8pt d-flex">
                    <a href="https://behaviorprisecollege.ca/" target="_blank" className="text-70 text-underline mr-8pt small">Visit College Website</a>
                </p>
                <p className="text-50 small mt-n1 mb-0">Copyright 2022 © All rights reserved.</p>
            </div>
        </div>
    );
}

export default Footer;

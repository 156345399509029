import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";


const config = {
    apiKey: "AIzaSyBj6kzHnspEN8ZO9q2Bp5uoebIspUNR-yU",
    authDomain: "behaviorprise-college-portal.firebaseapp.com",
    projectId: "behaviorprise-college-portal",
    storageBucket: "behaviorprise-college-portal.appspot.com",
    messagingSenderId: "1002222563342",
    appId: "1:1002222563342:web:f8c904e807677a9dc10c5f",
    measurementId: "G-WYLCT0C641"
};

if (!firebase.apps.length) {
    firebase.initializeApp(config);
}

export const firestore = firebase.firestore();
export const storage = firebase.storage();
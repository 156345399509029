import React from 'react';

const StudentList = (props) => {
    return (
        <tr>
            <td>
                <div className="media flex-nowrap align-items-center" style={{ whiteSpace: 'nowrap' }}>
                    <div className="avatar avatar-sm mr-8pt">
                        <span className="avatar-title rounded-circle">
                            <img src={props.student.profilePhotoUrl === "" ? "/assets/images/logo/user.png" : props.student.profilePhotoUrl}
                                alt="people" width={100} className="avatar-img rounded-circle" />
                        </span>
                    </div>
                    <div className="media-body">
                        <div className="d-flex flex-column">
                            <p className="mb-0"><strong className="js-lists-values-employee-name">{props.student.displayName}</strong></p>
                            <small className="js-lists-values-employee-email text-50">{props.student.emailAddress}</small>
                        </div>
                    </div>
                </div>
            </td>
            <td className="text-50 js-lists-values-activity small">
                <strong className="js-lists-values-employee-name">{props.student.studentNumber}</strong>
            </td>
            <td>
                <div className="d-flex align-items-center">
                    <a href className="text-70"><span className="js-lists-values-employer-name">{props.student.program}</span></a>
                </div>
            </td>

            <td>
                <a href={`/result/${props.student.id}`} className="chip chip-outline-primary" >Compute Result</a>
            </td>
            <td>
                <a href className="chip chip-outline-secondary" onClick={props.handleDelete}>Delete Profile</a>
            </td>

        </tr>
    );
}

export default StudentList;

import React from 'react';

const ResultList = (props) => {
    return (
        <tr>
            <td> </td>
            <td>
                <div className="flex">
                    <a className="card-title js-lists-values-course" href={`/results/details/${props.result.studentId}/${props.result.id}`}>
                        {props.result.resultCohort}</a>
                </div>
            </td>
            <td className="text-50 js-lists-values-activity small text-center" >{props.result.semester}</td>

            <td className="text-50 js-lists-values-activity small">{props.result.dateCreated}</td>
            <td>

                <a href={`/results/details/${props.result.studentId}/${props.result.id}`} className="chip chip-outline-primary">View Result</a>

            </td>
        </tr>
    );
}

export default ResultList;

import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import Header from './Header';
import Footer from './Footer';
import { useParams } from 'react-router-dom';
import { firestore } from "../../firebase/config";
import TableHeadColumn from './TableHeadColumn';
import TableBodyRow from './TableBodyRow';

const ResultDetails = () => {

    const resultId = useParams().id;

    const [ courseList, setCourseList ] = useState([]);
    const [ courseMap, setCourseMap ] = useState([]);
    const [ assessmentList, setAssessmentList ] = useState([]);
    const [ assessmentTitleList, setAssessmentTitleList ] = useState([]);
    const [ distinctAssessmentList, setDistinctAssessmentList ] = useState([]);

    useEffect(() => {

        firestore
            .collection('courseResult')
            .doc(resultId)
            .collection('courses')
            .onSnapshot(snap => {
                let ass = [];
                let courseMap = new Map()
                const courses = snap.docs.map((doc) => {
                    /// request each course assessment score
                    //scoreMap = {assessment, score}
                    const scoreMap = new Map()
                    firestore
                        .collection('score')
                        .doc(doc.id)
                        .collection('testscores')
                        .onSnapshot(snap => {
                            const testScoreList = snap.docs.map((assessmentDoc) => {
                                scoreMap.set(assessmentDoc.data().assessmentTitle, assessmentDoc.data().testScore)
                                return ({
                                    id: assessmentDoc.id,
                                    ...assessmentDoc.data()
                                })
                            });/// test score list
                            ass = [ ...ass, ...testScoreList ]
                            setAssessmentList(ass);
                            distinguishAssessments(ass)
                        });///get each course assessments

                    ///courseMap = {course, scoreMap}
                    courseMap.set(doc.data().courseTitle, scoreMap)
                    return ({
                        id: doc.id,
                        ...doc.data()
                    })
                });///get all the courses
                setCourseList(courses);
                ///console.log("all maps: ", courseMap)
                setCourseMap(courseMap);
            });
    }, []);




    const distinguishAssessments = (assessments) => {
        let distinctAss = []
        assessments.forEach((assessment) => {
            distinctAss = [ ...distinctAss, assessment.assessmentTitle ]
        })
        setDistinctAssessmentList(distinctAss.filter(onlyUnique).sort())
    }

    function onlyUnique (value, index, self) {
        return self.indexOf(value) === index;
    }



    return (
        <div className="mdk-drawer-layout js-mdk-drawer-layout" data-push data-responsive-width="992px">
            <div className="mdk-drawer-layout__content page-content">
                <Header />
                <div className="pt-32pt">
                    <div className="container page__container d-flex flex-column flex-md-row align-items-center text-center text-sm-left">
                        <div className="flex d-flex flex-column flex-sm-row align-items-center">
                            <div className="mb-24pt mb-sm-0 mr-sm-24pt">
                                <h2 className="mb-0">Student Result</h2>
                                <ol className="breadcrumb p-0 m-0">
                                    <li className="breadcrumb-item"><a href="admin-dashboard">Home</a></li>
                                    <li className="breadcrumb-item active">
                                        Result Details
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Page Content */}
                <div className="container page__container page-section">
                    <div>
                        <div className="page-separator">
                            <div className="page-separator__text">Result Details</div>
                        </div>

                        <div className="row mb-lg-12pt">
                            <div className="card pb-24pt">
                                <div className="table-responsive">
                                    <table className="table table-bordered table-flush mb-0 thead-border-top-0">
                                        <thead>
                                            <TableHeadColumn courseList={courseList} resultId={resultId} />
                                        </thead>
                                        <tbody className="list" id="contacts">
                                            <TableBodyRow distinctAssessmentList={distinctAssessmentList} courseList={courseList} courseMap={courseMap} />
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                {/* // END Page Content */}
                <Footer />
            </div>
            <Navbar />
        </div>
    );
}

export default ResultDetails;

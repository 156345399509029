import firebase from 'firebase/app';
import { firestore } from "./config"
import 'firebase/auth';


export const registerStudent = async ({ firstName, lastName, emailAddress, studentNumber, gender, program, cohort }) => {
    ///create default password
    let password = lastName + firstName;
    const resp = await firebase.auth().createUserWithEmailAndPassword(emailAddress, password.toLowerCase());
    const user = resp.user;
    await user.updateProfile({ displayName: `${firstName} ${lastName}` });
    ///send verification mail  
    await user.sendEmailVerification();
    ///resset student password
    await firebase.auth().sendPasswordResetEmail(emailAddress);
    await createUserDocument(user, firstName, lastName, studentNumber, gender, program, cohort);
    return user;
}


export const registerInstructor = async ({ emailAddress, firstName, lastName, gender }) => {
    ///create default password
    let password = lastName + firstName;
    const resp = await firebase.auth().createUserWithEmailAndPassword(emailAddress, password.toLowerCase());
    const user = resp.user;
    await user.updateProfile({ displayName: `${firstName} ${lastName}` });
    ///send verification mail  
    await user.sendEmailVerification();
    ///resset student password
    await firebase.auth().sendPasswordResetEmail(emailAddress);
    await createInstructorDocument(user, firstName, lastName, gender);
    return user;
}


export const createUserDocument = async (user, firstName, lastName, studentNumber, gender, program, cohort) => {
    const docRef = firestore.doc(`/students/${user.uid}`);
    ///create user object
    const userProfile = {
        uid: user.uid,
        displayName: user.displayName,
        studentNumber: studentNumber,
        emailAddress: user.email,
        firstName: firstName,
        lastName: lastName,
        gender: gender,
        program: program,
        cohort: cohort,
        studentship: "active",
        isAdmin: false,
        profilePhotoUrl: "",
        dateCreated: new Date().toLocaleDateString()
    };
    ///write to cloud firestore
    return docRef.set(userProfile);
}


export const createInstructorDocument = async (user, firstName, lastName, gender) => {
    const docRef = firestore.doc(`/instructors/${user.uid}`);
    ///create instructor object
    const userProfile = {
        uid: user.uid,
        displayName: user.displayName,
        emailAddress: user.email,
        firstName: firstName,
        lastName: lastName,
        gender: gender,
        isAdmin: true,
        role: "instructor",
        profilePhotoUrl: "",
        dateCreated: new Date().toLocaleDateString()
    };
    ///write to cloud firestore
    return docRef.set(userProfile);
}


export const createProgram = async ({ programTitle, programLength, certification }) => {
    firestore.collection('program')
        .add({
            programTitle: programTitle,
            programLength: programLength,
            certification: certification,
            dateCreated: new Date().toLocaleDateString()
        });
}


export const createCohort = async ({ cohortTitle, cohortStartDate }) => {
    firestore.collection('cohort')
        .add({
            cohortTitle: cohortTitle,
            cohortStartDate: cohortStartDate,
            dateCreated: new Date().toLocaleDateString()
        });
}

export const createCourse = async ({ courseTitle, numberAssessment }) => {
    firestore.collection('course')
        .add({
            courseTitle: courseTitle,
            numberAssessment: numberAssessment,
            dateCreated: new Date().toLocaleDateString()
        });
}


export const createAssessment = async ({ assessmentTitle }) => {
    firestore.collection('assessment')
        .add({
            assessmentTitle: assessmentTitle,
            dateCreated: new Date().toLocaleDateString()
        });
}


export const createResult = async ({ resultCohort, semester, studentId }) => {
    firestore.collection(`result/${studentId}/results`)
        .add({
            resultCohort: resultCohort,
            studentId: studentId,
            semester: semester,
            status: "not publish",
            dateCreated: new Date().toLocaleDateString()
        });
}

export const createCourseResult = async (resultId, courseTitle, courseSemesterTotal, coursePercentage) => {
    return firestore.collection(`courseResult/${resultId}/courses`)
        .add({
            resultId: resultId,
            courseTitle: courseTitle,
            courseSemesterTotal: courseSemesterTotal,
            coursePercentage: coursePercentage,
            dateCreated: new Date().toLocaleDateString()
        });
}

export const createResultScore = async (resultId, courseResultId, courseTitle, assessmentTitle, testScore) => {
    console.log("spread:", assessmentTitle + ": and : " + testScore)
    firestore.collection(`score/${courseResultId}/testscores`)
        .add({
            resultId: resultId,
            courseResultId: courseResultId,
            courseTitle: courseTitle,
            assessmentTitle: assessmentTitle,
            testScore: testScore,
            dateCreated: new Date().toLocaleDateString()
        });
}

export const publishStudentResult = async (studentId, resultId) => {
    const docRef = await firestore.doc(`result/${studentId}/results/${resultId}`);
    return docRef.update({
        status: "published",
    });
}
import React from 'react';

const Navbar = () => {
    return (
        <div className="mdk-drawer js-mdk-drawer" id="default-drawer">
            <div className="mdk-drawer__content">
                <div className="sidebar sidebar-dark-pickled-bluewood sidebar-left" data-perfect-scrollbar>
                    <a href="index.html" className="sidebar-brand ">
                        <span className="avatar avatar-xl sidebar-brand-icon h-auto">
                            <span className="avatar-title rounded bg-white"><img src="assets/images/logo/logo.png" className="img-fluid" alt="logo" /></span>
                        </span>
                        <span> Behaviorprise </span>
                    </a>
                    <div className="sidebar-heading">Student</div>
                    <ul className="sidebar-menu">
                        <li className="sidebar-menu-item">
                            <a className="sidebar-menu-button" href="dashboard.html">
                                <span className="material-icons sidebar-menu-icon sidebar-menu-icon--left">home</span>
                                <span className="sidebar-menu-text">Home</span>
                            </a>
                        </li>
                        <li className="sidebar-menu-item">
                            <a className="sidebar-menu-button" href="account.html">
                                <span className="material-icons sidebar-menu-icon sidebar-menu-icon--left">account_box</span>
                                <span className="sidebar-menu-text">My Profile</span>
                            </a>
                        </li>
                        <li className="sidebar-menu-item">
                            <a className="sidebar-menu-button" href="program.html">
                                <span className="material-icons sidebar-menu-icon sidebar-menu-icon--left">timeline</span>
                                <span className="sidebar-menu-text">My Program</span>
                            </a>
                        </li>
                        <li className="sidebar-menu-item">
                            <a className="sidebar-menu-button" href="results.html">
                                <span className="material-icons sidebar-menu-icon sidebar-menu-icon--left">assignment_turned_in</span>
                                <span className="sidebar-menu-text">Assessment Result</span>
                            </a>
                        </li>
                        <li className="sidebar-menu-item">
                            <a className="sidebar-menu-button" href="payment-history.html">
                                <span className="material-icons sidebar-menu-icon sidebar-menu-icon--left">assignment_turned_in</span>
                                <span className="sidebar-menu-text">Payment History</span>
                            </a>
                        </li>
                        <li className="sidebar-menu-item">
                            <a className="sidebar-menu-button" href="login.html">
                                <span className="material-icons sidebar-menu-icon sidebar-menu-icon--left">assignment_turned_in</span>
                                <span className="sidebar-menu-text">Logout</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Navbar;

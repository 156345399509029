import React, { useState, useEffect } from 'react';
import { firestore } from "../../firebase/config"


const SelectAssesment = () => {
    const [ assessments, setAssessments ] = useState([]);

    useEffect(() => {
        firestore
            .collection('assessment')
            .onSnapshot(snap => {
                const assessment = snap.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setAssessments(assessment);
            });
    }, []);

    if (!assessments) {
        return (<div></div>)
    }
    return (
        <>
            <option selected value="">Select Assessment</option>
            {
                assessments.map((assessment, index) => {
                    return (
                        <option value={assessment.assessmentTitle} key={index}>{assessment.assessmentTitle}</option>)
                })
            }
        </>
    )
}

export default SelectAssesment;

import firebase from 'firebase/app';
import 'firebase/auth';

export const resetPassword = async ({ emailAddress }) => {
    firebase.auth().sendPasswordResetEmail(emailAddress)
        .then(function (result) {
        }).catch(function (error) {
        });
}

export const login = async ({ emailAddress, password }) => {
    const resp = await firebase.auth().signInWithEmailAndPassword(emailAddress, password);
    return resp;
}

export const logout = () => {
    return firebase.auth().signOut();
}

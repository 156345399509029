import React from 'react';

const TableBodyRow = (props) => {

    const findScore = (courseTitle, assessment) => {
        let courseScores = props.courseMap.get(courseTitle)
        if (courseScores) {
            let score = courseScores.get(assessment)
            if (score) {
                return score;
            }
        }
        return "";
    }
    return (
        <>
            {
                props.distinctAssessmentList.map((assessment, index) => {
                    return <tr key={index}>
                        <td>
                        </td>
                        <td className="">
                            <small className="js-lists-values-name text-black-100">{assessment}</small>
                        </td>
                        {
                            props.courseList.map((course, index) => {
                                return <td className="text-center" key={index}>
                                    <small className="js-lists-values-name text-black-100">{findScore(course.courseTitle, assessment)} </small>
                                </td>
                            })
                        }
                        <td>
                        </td>
                    </tr>
                })}

            <tr>
                <td>
                </td>
                <td className="">
                    <small className="js-lists-values-name text-black-100">Semester Total</small>
                </td>
                {
                    props.courseList.map((course, index) => {
                        return <th className="text-center" key={index}>
                            <div className="lh-1 d-flex flex-column text-black my-4pt">
                                {course.courseSemesterTotal}
                            </div>
                        </th>
                    })
                }
            </tr>

            <tr>
                <td>
                </td>
                <td className="">
                    <small className="js-lists-values-name text-black-100">Percentages</small>
                </td>
                {
                    props.courseList.map((course, index) => {
                        return <th className="text-center" key={index}>
                            <div className="lh-1 d-flex flex-column text-black my-4pt">
                                {course.coursePercentage}%
                            </div>
                        </th>
                    })
                }
            </tr>
        </>
    )
}

export default TableBodyRow;

import React from 'react';
import { Link } from 'react-router-dom';
import { logout } from '../../firebase/auth';
import { useHistory } from 'react-router-dom';

const Navbar = (props) => {
    const history = useHistory();

    const role = localStorage.getItem('role');

    const logoutUser = async () => {
        await logout();
        history.push('/');
    };
    return (
        <div className="mdk-drawer js-mdk-drawer layout-compact__drawer" id="default-drawer">
            <div className="mdk-drawer__content js-sidebar-mini" data-responsive-width="992px" data-layout="compact">
                <div className="sidebar sidebar-mini sidebar-dark-pickled-bluewood sidebar-left d-flex flex-column">
                    {/* Brand */}
                    <a href="/admin-dashboard" className="sidebar-brand p-0 navbar-height d-flex justify-content-center">
                        <span className="avatar avatar-sm ">
                            <span className="avatar-title rounded bg-primary"><img src="/assets/images/logo/logo_sm.png" className="img-fluid" alt="logo" /></span>
                        </span>
                    </a>
                    <div className="flex d-flex flex-column justify-content-start" data-perfect-scrollbar>
                        <ul className="nav flex-shrink-0 flex-nowrap flex-column sidebar-menu mb-0 sm-item-bordered js-sidebar-mini-tabs" role="tablist">
                            <li className="sidebar-menu-item" data-title="Student" data-placement="right">
                                <a className="sidebar-menu-button" href="/students">
                                    <i className="sidebar-menu-icon sidebar-menu-icon--left material-icons">people_outline</i>
                                    <span className="sidebar-menu-text">Students</span>
                                </a>
                            </li>
                            {
                                role !== "instructor" ?
                                    <li className="sidebar-menu-item" data-title="Program" data-placement="right" data-container="body" data-boundary="window">
                                        <a className="sidebar-menu-button" href="/programs">
                                            <i className="sidebar-menu-icon sidebar-menu-icon--left material-icons">school</i>
                                            <span className="sidebar-menu-text">Programs</span>
                                        </a>
                                    </li> : ""
                            }
                            <li className="sidebar-menu-item" data-title="Course" data-placement="right" data-container="body" data-boundary="window">
                                <a className="sidebar-menu-button" href="/courses">
                                    <i className="sidebar-menu-icon sidebar-menu-icon--left material-icons">content_copy</i>
                                    <span className="sidebar-menu-text">Courses</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item" data-title="Course" data-placement="right" data-container="body" data-boundary="window">
                                <a className="sidebar-menu-button" href="/assessments">
                                    <i className="sidebar-menu-icon sidebar-menu-icon--left material-icons">assignment_turned_in</i>
                                    <span className="sidebar-menu-text">Assessment</span>
                                </a>
                            </li>
                            {
                                role !== "instructor" ?
                                    <>
                                        <li className="sidebar-menu-item" data-title="Cohort" data-placement="right" data-container="body" data-boundary="window">
                                            <a className="sidebar-menu-button" href="/cohorts">
                                                <i className="sidebar-menu-icon sidebar-menu-icon--left material-icons">donut_large</i>
                                                <span className="sidebar-menu-text">Cohorts</span>
                                            </a>
                                        </li>
                                        <li className="sidebar-menu-item" data-title="Instructors" data-placement="right" data-container="body" data-boundary="window">
                                            <a className="sidebar-menu-button" href="/instructors">
                                                <i className="sidebar-menu-icon sidebar-menu-icon--left material-icons">people</i>
                                                <span className="sidebar-menu-text">Instructors</span>
                                            </a>
                                        </li>
                                    </> : ""

                            }

                        </ul>
                    </div>

                </div>

            </div>
        </div>
    );
}

export default Navbar;

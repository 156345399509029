import React from 'react';

const TimetableList = () => {
    return (
        <tr>
            <td></td>
            <td>
                <div className="flex">
                    <a className="card-title js-lists-values-course" href>2022 August Cohort</a>
                </div>
            </td>
            <td className="text-50 js-lists-values-activity small">21 April 2022</td>
            <td className="text-50 js-lists-values-activity small">2</td>
            <td className="text-50 js-lists-values-activity small">7 April 2022</td>
            <td>
                <a href className="chip chip-outline-success">Active</a>
            </td>
            <td>
                <a href className="chip chip-outline-danger">View Timetable</a>
            </td>
            <td>
                <a href className="chip chip-outline-secondary">Delete</a>
            </td>
        </tr>
    );
}

export default TimetableList;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { logout } from '../../firebase/auth';
import { useHistory, Redirect } from 'react-router-dom';
import { firestore } from "../../firebase/config";

const Header = (props) => {
    const history = useHistory();

    const [ students, setStudents ] = useState([]);
    const [ programs, setPrograms ] = useState([]);
    const [ courses, setCourses ] = useState([]);

    useEffect(() => {
        firestore
            .collection('students')
            .onSnapshot(snap => {
                const student = snap.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setStudents(student);
            });

        firestore
            .collection('program')
            .onSnapshot(snap => {
                const program = snap.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setPrograms(program);
            });

        firestore
            .collection('course')
            .onSnapshot(snap => {
                const course = snap.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setCourses(course);
            });
    }, []);

    const logoutUser = async () => {
        ///remove session
        localStorage.clear();
        history.push('/');
        await logout();
    };

    const auth = localStorage.getItem('auth');
    if (auth !== "loggedIn") {
        return <Redirect to="/" />
    }

    return (
        <div className="navbar navbar-expand pr-0 navbar-light border-bottom-2" d="default-navbar" data-primary>
            <button className="navbar-toggler w-auto mr-16pt d-block d-lg-none rounded-0" type="button" data-toggle="sidebar">
                <span className="material-icons">short_text</span>
            </button>
            <a href="admin-dashboard" className="navbar-brand mr-16pt d-lg-none">
                <span className="avatar avatar-sm navbar-brand-icon mr-0 mr-lg-8pt">
                    <span className="avatar-title rounded bg-primary">
                        <img src="/assets/images/logo/logo_sm.png" alt="logo" className="img-fluid" /> </span>
                </span>
                <span className="d-none d-lg-block">Behaviorprise College</span>
            </a>
            <span className="d-none d-md-flex align-items-center mr-16pt">
                <span className="avatar avatar-sm mr-12pt">
                    <span className="avatar-title rounded navbar-avatar"><i className="material-icons">people</i></span>
                </span>
                <small className="flex d-flex flex-column">
                    <strong className="navbar-text-100">Students</strong>
                    <span className="navbar-text-50">{students.length}</span>
                </small>
            </span>
            <span className="d-none d-md-flex align-items-center mr-16pt">
                <span className="avatar avatar-sm mr-12pt">
                    <span className="avatar-title rounded navbar-avatar"><i className="material-icons">school</i></span>
                </span>
                <small className="flex d-flex flex-column">
                    <strong className="navbar-text-100">Programs</strong>
                    <span className="navbar-text-50">{students.length}</span>
                </small>
            </span>
            <div className="flex" />
            <div className="nav navbar-nav flex-nowrap d-flex mr-16pt">
                <div className="nav-item dropdown">
                    <a href="#" className="nav-link d-flex align-items-center dropdown-toggle" data-toggle="dropdown" data-caret="false">
                        <span className="avatar avatar-sm mr-8pt2">
                            <img width={36} height={36} className="rounded-circle" src="/assets/images/logo/user.png" alt="account" />
                        </span>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right">
                        <div className="dropdown-header"><strong>Account</strong></div>
                        <Link className="dropdown-item" onClick={logoutUser}>Logout</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;

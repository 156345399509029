import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import Header from './Header';
import Footer from './Footer';
import { useParams } from 'react-router-dom';
import { firestore } from "../../firebase/config";
import TableHeadColumn from '../admin/TableHeadColumn';
import TableBodyRow from '../admin/TableBodyRow';

const ResultDetails = (props) => {


    const resultId = useParams().id;

    const [ courseList, setCourseList ] = useState([]);
    const [ courseMap, setCourseMap ] = useState([]);
    const [ assessmentList, setAssessmentList ] = useState([]);
    const [ assessmentTitleList, setAssessmentTitleList ] = useState([]);
    const [ distinctAssessmentList, setDistinctAssessmentList ] = useState([]);

    useEffect(() => {

        firestore
            .collection('courseResult')
            .doc(resultId)
            .collection('courses')
            .onSnapshot(snap => {
                let ass = [];
                let courseMap = new Map()
                const courses = snap.docs.map((doc) => {
                    /// request each course assessment score
                    //scoreMap = {assessment, score}
                    const scoreMap = new Map()
                    firestore
                        .collection('score')
                        .doc(doc.id)
                        .collection('testscores')
                        .onSnapshot(snap => {
                            const testScoreList = snap.docs.map((assessmentDoc) => {
                                scoreMap.set(assessmentDoc.data().assessmentTitle, assessmentDoc.data().testScore)
                                return ({
                                    id: assessmentDoc.id,
                                    ...assessmentDoc.data()
                                })
                            });/// test score list
                            ass = [ ...ass, ...testScoreList ]
                            setAssessmentList(ass);
                            distinguishAssessments(ass)
                        });///get each course assessments

                    ///courseMap = {course, scoreMap}
                    courseMap.set(doc.data().courseTitle, scoreMap)
                    return ({
                        id: doc.id,
                        ...doc.data()
                    })
                });///get all the courses
                setCourseList(courses);
                ///console.log("all maps: ", courseMap)
                setCourseMap(courseMap);
            });
    }, []);




    const distinguishAssessments = (assessments) => {
        let distinctAss = []
        assessments.forEach((assessment) => {
            distinctAss = [ ...distinctAss, assessment.assessmentTitle ]
        })
        setDistinctAssessmentList(distinctAss.filter(onlyUnique).sort())
    }

    function onlyUnique (value, index, self) {
        return self.indexOf(value) === index;
    }


    return (
        <div>
            <div className="mdk-header-layout js-mdk-header-layout">
                <Header showExtraHeader={false} />

                <div className="container page__container mt-64pt">
                    <ul className="nav navbar-nav">
                        <li className="nav-item mt-32pt">
                            <span onClick={() => { props.history.goBack() }} className="nav-link text-70" style={{ cursor: 'pointer' }}><i className="material-icons icon--left">keyboard_backspace</i> Back to Results</span>
                        </li>
                    </ul>
                </div>

                <div className="container page__container page-section">
                    <div>
                        <div className="page-separator">
                            <div className="page-separator__text">Result Details</div>
                        </div>

                        <div className="row mb-lg-12pt">
                            <div className="card pb-24pt">
                                <div className="table-responsive">
                                    <table className="table table-bordered table-flush mb-0 thead-border-top-0">
                                        <thead>
                                            <TableHeadColumn courseList={courseList} resultId={resultId} />
                                        </thead>
                                        <tbody className="list" id="contacts">
                                            <TableBodyRow distinctAssessmentList={distinctAssessmentList} courseList={courseList} courseMap={courseMap} />
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

                <Footer />
            </div>
            <Navbar />
        </div>
    );
}

export default ResultDetails;
